<template>
  <section class="all-templates-view" v-loading.fullscreen.lock="loading">
    <el-row class="cardsList" type="flex" justify="center" align="center">
      <el-col :span="22">
        <el-row style="margin-top: -10px; margin-bottom: 5px">
          <!-- <router-link to="/apps/published"> -->
          <el-button size="mini" @click="handleBack">
            <i class="el-icon-back"></i> back</el-button
          >
          <!-- </router-link> -->
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :lg="12" :md="12" :sm="24" :xs="24">
            <h3 class="title fw-normal text-dark mb-0 ls-05">
              {{ "Publish Your Application" | getMenuTitle }}
            </h3>
          </el-col>
        </el-row>
        <el-card class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-steps
                :active="active"
                finish-status="success"
                simple
                style="margin-top: 20px"
              >
                <el-step
                  title="Basic Info"
                  @click.native="stepNavigation(0)"
                ></el-step>
                <!-- <el-step
                  title="Configurations"
                  @click.native="stepNavigation(1)"
                ></el-step> -->
                <el-step
                  title="App Preview"
                  @click.native="stepNavigation(1)"
                ></el-step>
              </el-steps>
            </el-col>
          </el-row>
          <hr />
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form label-position="top" v-if="active == 0">
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      label="Title"
                      :rules="[
                        {
                          required: true,
                          message: 'Please provide valid title',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-input
                        type="text"
                        placeholder="Title"
                        v-model="publishApp.name"
                        :maxlength="30"
                        show-word-limit
                        style="width: 400px"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Version"
                      :rules="[
                        {
                          required: true,
                          message: 'Please provide valid version (Number)',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-input
                        type="number"
                        placeholder="Number (Ex:1.0)"
                        v-model="publishApp.version"
                        style="width: 400px"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item
                      label="Overview"
                      :rules="[
                        {
                          required: true,
                          message: 'Please provide valid Description',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-input
                        type="textarea"
                        placeholder="Description"
                        v-model="publishApp.singleLineDescription"
                        :maxlength="100"
                        style="width: 400px"
                        :rows="3"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="Select App Category"
                      :rules="[
                        {
                          required: true,
                          message: 'Please provide app category',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        style="margin-right: 10px; width: 195px"
                        placeholder="Industrial Type"
                        v-model="publishApp.industries"
                        clearable
                        multiple
                        collapse-tags
                        filterable
                        remote
                        :multiple-limit="4"
                      >
                        <el-option
                          v-for="item in allIndustries"
                          :key="item._id"
                          :label="item.name"
                          :value="item.name"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        style="margin-right: 10px; width: 195px"
                        placeholder="Select Category"
                        v-model="publishApp.categories"
                        clearable
                        multiple
                        collapse-tags
                        filterable
                        remote
                      >
                        <el-option-group
                          v-for="(
                            categories, industry
                          ) in selectedIndustriesCategories"
                          :key="industry"
                          :label="industry"
                        >
                          <el-option
                            v-for="category in categories"
                            :key="category"
                            :label="category"
                            :value="category"
                          ></el-option>
                        </el-option-group>
                      </el-select>
                      <el-tooltip content="Create Category">
                        <i
                          class="el-icon-circle-plus-outline"
                          @click="categoryDialogVisible = true"
                          style="cursor: pointer"
                        ></i>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item
                  label="Detailed Description"
                  :rules="[
                    {
                      required: true,
                      message: 'Minimum length is 100 characters',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input
                    type="textarea"
                    placeholder="Description (Text must be greater than 100 characters)"
                    v-model="publishApp.description"
                    style="width: 950px"
                    :rows="6"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Application Logo"
                  class="required-fields-info"
                  :rules="[
                    {
                      required: true,
                      message: 'Please provide valid Logo',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <div class="button-and-upload-container">
                    <div class="select-logo">
                      <el-button
                        type="text"
                        class="mb-5 text-center"
                        @click="iconPickerDialogVisible = true"
                      >
                        <img
                          :src="
                            require('@/assets/img/icons/menu/' +
                              publishApp.icon)
                          "
                          alt="menu"
                          width="35"
                          v-if="publishApp.icon"
                        />
                        <!-- <img src="@/assets/img/icons/menu/dashboard.svg" alt="menu" width="35" v-else /> -->
                        <p v-else>Select Logo</p>

                        <!-- <img src="@/assets/img/icons/add-entity-icon.svg" alt="icon" /> -->
                      </el-button>
                    </div>
                    <div style="margin-left: 8px">or</div>
                    <div class="upload-container">
                      <el-upload
                        action
                        :on-change="checkToUploadLogo"
                        :show-file-list="false"
                        :auto-upload="false"
                        accept="image/*"
                        :disabled="userUploadedLogo.length > 0"
                      >
                        <div v-if="userUploadedLogo.length">
                          <div
                            class="filename"
                            v-for="file in userUploadedLogo"
                            :key="file.uid"
                          >
                            <a class="el-upload-list__item-name">
                              <img
                                :src="getFileURL(file.raw)"
                                alt="Uploaded Image"
                                style="width: 50px; height: 50px"
                              />
                              <a @click.stop="deleteLogo()">
                                <i class="el-icon-circle-close"></i>
                              </a>
                            </a>
                            <div class="direction-buttons"></div>
                          </div>
                        </div>
                        <el-button size="small" type="primary" v-else
                          >Click to upload</el-button
                        >
                      </el-upload>
                    </div>
                  </div>
                  <!-- <el-upload action="#" list-type="picture-card" :auto-upload="false">
                                        <i slot="default" class="el-icon-plus"></i>
                                        <div slot="file" slot-scope="{file}">
                                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                                            <span class="el-upload-list__item-actions">
                                                <span class="el-upload-list__item-preview"
                                                    @click="handlePictureCardPreview(file)">
                                                    <i class="el-icon-zoom-in"></i>
                                                </span>
                                                 <span v-if="!disabled" class="el-upload-list__item-delete"
                                                    @click="handleDownload(file)">
                                                    <i class="el-icon-download"></i>
                                                </span> 
                                                <span v-if="!disabled" class="el-upload-list__item-delete"
                                                    @click="handleRemove(file)">
                                                    <i class="el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </el-upload> -->
                </el-form-item>
                <!-- <el-form-item>
                                    <el-button @click="newEntityModal = false" size="mini">Cancel</el-button>
                      <el-button
                        size="mini"
                        type="primary"
                        :disabled="!entityName.trim()"
                        @click="createEntity"
                      >Confirm</el-button>
                                </el-form-item> -->
              </el-form>
              <!-- <el-form label-position="top" v-if="active == 1">
                <el-row>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                    <el-form-item
                      label="Groups"
                      :rules="[
                        {
                          message: 'Group is required',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        v-model="publishApp.groups"
                        placeholder="Select Groups"
                        multiple
                        filterable
                        collapse-tags
                        @change="fetchGroupsData"
                      >
                        <el-option
                          v-for="(item, index) of getAllEntityGroupsData.data"
                          v-bind:key="index"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                    <el-form-item
                      label="Entities"
                      :rules="[
                        {
                          required: true,
                          message: 'Entity is required',
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        v-model="publishApp.entities"
                        placeholder="Select Entities"
                        multiple
                        filterable
                        collapse-tags
                      >
                        <el-option
                          v-for="(item, index) of allEntities"
                          v-bind:key="index"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                                        <el-form-item label="Templates" :rules="[
                                            {
                                              required: true,
                                              message: 'Template is required',
                                              trigger: ['blur', 'change'],
                                            }
                                          ]">
                                            <el-select v-model="publishApp.templates" placeholder="Select Templates"
                                                multiple filterable collapse-tags>
                                                <el-option v-for="(item, index) of allTemplates" v-bind:key="index"
                                                    :label="item.name" :value="item._id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                    <el-form-item label="Roles">
                      <el-select
                        v-model="publishApp.menuManagementWithRoles"
                        placeholder="Select Roles"
                        multiple
                        filterable
                        collapse-tags
                      >
                        <el-option
                          v-for="(item, index) of allRoles"
                          v-bind:key="index"
                          :label="item.title"
                          :value="item._id"
                          :disabled="item.title === 'SA'"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                                        <el-form-item label="Filters">
                                            <el-select v-model="publishApp.filters" placeholder="Select Filters" multiple
                                                filterable collapse-tags>
                                                <el-option v-for="(item, index) of getFiltersList" v-bind:key="index"
                                                    :label="item.filter_name" :value="item._id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                  <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24">
                                        <el-form-item label="Custom Dashboards">
                                            <el-select v-model="publishApp.dashboards" placeholder="Select Dashboards"
                                                multiple filterable collapse-tags>
                                                <el-option v-for="(item, index) of customDashBoards" v-bind:key="index"
                                                    :label="item.name" :value="item._id"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                </el-row>
              </el-form> -->
              <el-form v-if="active == 1">
                <el-form-item
                  label="Preview Images"
                  :rules="[
                    {
                      required: true,
                      message: 'Application preview images are required',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                />
                <el-row type="flex" justify="center" class="name-types">
                  <div class="upload-container">
                    <el-upload
                      action
                      :on-change="checkToUploadDocument"
                      :show-file-list="false"
                      :auto-upload="false"
                      accept="image/*"
                      multiple
                      drag
                    >
                      <div v-if="this.files.length">
                        <div
                          class="filename"
                          v-for="(file, index) in files"
                          :key="JSON.stringify(file)"
                        >
                          <a class="el-upload-list__item-name">
                            <img
                              :src="
                                require('@/assets/img/icons/upload-active.svg')
                              "
                              alt="icon"
                              class="img-active"
                            />
                            <img
                              v-if="
                                file.raw.type == 'image/jpeg' ||
                                file.raw.type == 'image/jpg'
                              "
                              :src="require('@/assets/img/icons/JPG.svg')"
                              alt="icon"
                              class="img-fluid"
                            />
                            <img
                              v-else-if="file.raw.type == 'image/png'"
                              :src="require('@/assets/img/icons/PNG.svg')"
                              alt="icon"
                              class="img-fluid"
                            />

                            <img
                              v-else
                              :src="require('@/assets/img/icons/pdf.svg')"
                              alt="icon"
                              class="img-fluid"
                            />
                            {{ file.raw.name }}
                          </a>
                          <div class="direction-buttons">
                            <!-- <el-button
                                                type="text"
                                                icon="el-icon-bottom"
                                                class="directions"
                                                @click="moveDown(index)"
                                              ></el-button>
                                              <el-button
                                                type="text"
                                                icon="el-icon-top"
                                                class="directions"
                                                @click="moveUp(index)"
                                              ></el-button> -->
                            <a @click.stop="deleteItem(index)">
                              <i class="el-icon-circle-close"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="upload-attchment-box centered" v-else>
                        <img
                          src="@/assets/img/fields/attachment-filling.svg"
                          alt="Upload File"
                          height="20"
                        />
                        <h4 class="ml-1">Upload a file</h4>
                      </div>
                    </el-upload>
                  </div>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
          <el-button
            style="margin-top: 12px"
            @click="previous()"
            v-if="active > 0"
            >Previous step</el-button
          >
          <el-button style="margin-top: 12px" @click="next()">
            <span v-if="active >= 0 && active <= 1"> Next step</span>
            <span v-if="active >= 2"> Finish</span>
          </el-button>
        </el-card>
      </el-col>
    </el-row>
    <dialog-component
      :title="'Select Menu Icon'"
      :visible="iconPickerDialogVisible"
      @before-close="CloseiconPickerDialogVisible"
      top="25px"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div
        class="iconslistScrollable"
        style="display: flex; flex-direction: column"
      >
        <div style="margin-bottom: 10px; align-self: flex-end; width: 30%">
          <el-input placeholder="Search by name" v-model="searchIcon" clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <el-row>
          <div class="tabs-scroll">
            <el-tabs v-model="selectedCategory">
              <el-tab-pane
                :label="category.name"
                :name="category.key"
                v-for="(category, i) in menuIcons"
                :key="category.key + '_' + i"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </el-row>

        <el-row class="icons-scroll" :gutter="10" style="margin-top: 15px">
          <el-col
            :xs="4"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="1"
            v-for="(icon, index) in getSelectedCategory"
            :key="index"
            class="border-radius"
          >
            <div
              class="grid-content ep-bg-purple"
              @click="setIconPath(icon, selectedCategory)"
              style="cursor: pointer"
            >
              <el-tooltip
                effect="dark"
                :content="icon.name"
                placement="top-start"
              >
                <div class="circle-icon">
                  <img
                    v-if="selectedCategory == 'default'"
                    :src="require('@/assets/img/icons/menu/' + icon.url)"
                    width="35"
                    height="36"
                    style="margin-top: 10px !important"
                  />
                  <img
                    v-else
                    :src="
                      require('@/assets/img/icons/menu/' +
                        selectedCategory +
                        '/' +
                        icon.url)
                    "
                    width="40"
                    height="41"
                    style="margin-top: 10px !important"
                  />
                </div>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Add New Category'"
      :visible="categoryDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="categoryDialogVisible = false"
      width="30%"
    >
      <div class="Category-in-MarketPlace">
        <el-select
          v-model="industryId"
          placeholder="select industry type"
          style="margin-bottom: 10px; width: 100%"
        >
          <el-option
            v-for="item in allIndustries"
            :key="item._id"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="newCategory"
          placeholder="Enter category name"
        ></el-input>
      </div>

      <div slot="footer">
        <el-button @click="categoryDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addCategory()"
          >Add category <i class="el-icon-circle-plus-outline"></i
        ></el-button>
      </div>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import axios from "axios";
import nodeAxios from "@/config/axios";
import app from "../../config/app";
const menuIconsData = require("@/assets/data/market_place.json");
const menuIconsDataWhite = require("@/assets/data/market_place_white.json");
// import draggable from "vuedraggable";
// import _ from "lodash";
export default {
  name: "Applications-Upload",
  components: {
    // draggable
  },
  data() {
    return {
      files: [],
      menuIcons: menuIconsData.categories,
      menuIconsWhite: menuIconsDataWhite,
      userUploadedLogo: [],
      iconPickerDialogVisible: false,
      active: 0,
      publishApp: {
        industries: [],
        categories: [],
        menuManagementWithRoles: [],
        logo: [],
        icon: "",
        version: 1.0,
      },
      entityGroupsData: [],
      allTemplates: [],
      allRoles: [],
      allEntities: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      customDashBoards: [],
      selectedCategory: "default",
      iconBgColor: "",
      iconColor: "white",
      searchIcon: "",
      search_string: "",
      configureLoading: false,
      activeStep: 0,
      newEntityModal: false,
      entityName: "",
      description: "",
      isAccount: false,
      entity_type: "GENERAL",
      modalLoading: false,
      showAddGroupDialog: false,
      groupName: "",
      loadingSaveGroup: false,
      loading: false,
      allIndustries: [],
      selectedCategories: [],
      categoryDialogVisible: false,
      newCategory: "",
      industryId: "",
      isFirstLoad: true,
    };
  },
  mixins: [PermissionsHelper, NavigationHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
    ]),
    ...mapGetters("apps", [
      "getAllApps",
      "getAppErrors",
      "getNewApplicationInfo",
      "getAllCategories",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entityGroups", [
      "getAllEntityGroupsData",
      "getSelectedGroupsData",
    ]),
    ...mapGetters("roles", [
      "getAllRoles",
      "getRoleById",
      "getSuccess",
      "getRoleErrors",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("filters", ["getFiltersList"]),
    ...mapGetters("customDashboard", ["getAllDashboards"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    // selectedIndustryType() {
    //     return this.allIndustries.find(item => item._id === this.selectedIndustryTypeName) || null;
    // },
    selectedIndustriesCategories() {
      // Get selected industry types and their corresponding categories
      const selectedCategoriesObj = {};
      for (const industry of this.selectedIndustriesData) {
        selectedCategoriesObj[industry.name] = industry.categories;
      }
      return selectedCategoriesObj;
    },
    selectedIndustriesData() {
      // Get selected industry types data
      return this.allIndustries.filter((industry) =>
        this.publishApp.industries.includes(industry.name)
      );
    },
    getSelectedCategory() {
      let selectedIcons = this.menuIcons.find(
        (e) => e.key == this.selectedCategory
      );
      if (selectedIcons?.icons) {
        if (this.searchIcon) {
          return selectedIcons.icons.filter((e) =>
            e.name.toLowerCase().includes(this.searchIcon.toLowerCase())
          );
        }
        return selectedIcons.icons;
      }
      return [];
    },
  },
  async mounted() {
    if (!this.checkPermissionByPermissionName("addApp")) {
      this.$notify.error({
        title: "Error",
        message: "You don't have permissions to uploads",
      });
      this.$router.push({ path: "/dashboard" });
    } else if (
      this.getCompanyDetails &&
      (this.getCompanyDetails.is_admin_clone ||
        this.getCompanyDetails.is_cloned)
    ) {
      this.$notify.error({
        title: "Error",
        message: "You can't Access uploads in an application",
      });
      this.$router.push({ path: "/dashboard" });
    } else {
      this.iconColor = this.getCompanyDetails.icon_color;
      this.setIconBgColor();
    //   await this.fetchAllApps();
      await this.fetchAllCategories();
    }
  },
  methods: {
    CloseiconPickerDialogVisible() {
      this.iconPickerDialogVisible = false;
    },
    getFileURL(file) {
      return URL.createObjectURL(file);
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async deleteLogo() {
      this.userUploadedLogo = [];
    },
    async checkToUploadDocument(file) {
      //   if (
      //     file.raw.type != "audio/mpeg" &&
      //     file.raw.type != "video/mp4" &&
      //     file.raw.type != "image/gif" &&
      //     file.raw.type != "text/html" &&
      //     file.raw.type != "text/css" &&
      //     file.raw.type != "text/javascript" &&
      //     file.raw.type != "application/json" &&
      //     file.raw.type != "" &&
      //     file.raw.type != "application/x-msdownload"
      //   ) {
      if (file.size < 25000000) {
        this.files.push(file);
      } else {
        this.$message.error("Large file. Maximum upload file size: 25 MB");
      }
      //   } else {
      //     this.$message("File format is not supported");
      //   }
    },
    async checkToUploadLogo(file) {
      if (file.size < 25000000) {
        this.userUploadedLogo.push(file);
        this.publishApp.icon = "";
      } else {
        this.$message.error("Large file. Maximum upload file size: 25 MB");
      }
    },
    setIconPath(icon, selectedCategory) {
      this.publishApp.icon =
        selectedCategory == "default"
          ? icon.url
          : `${selectedCategory}/${icon.url}`;
      this.userUploadedLogo = [];
      this.iconPickerDialogVisible = false;
    },
    async next() {
      this.loading = true;
      // if (this.active == 1) {
      //   if (this.publishApp.entities.length == 0) {
      //     this.loading = false;
      //     this.$message.warning("Please select entities");
      //     return;
      //   } else {
      //     this.active = 2;
      //     this.loading = false;
      //   }
      // } else 
      if (this.active == 1) {
        if (this.files && this.files.length) {
          var formData = new FormData();
          this.files.forEach((file) => {
            formData.append("files", file.raw !== undefined ? file.raw : file);
          });

          var logoFormData = new FormData();
          if (this.userUploadedLogo.length) {
            logoFormData.append(
              "files",
              this.userUploadedLogo[0].raw !== undefined
                ? this.userUploadedLogo[0].raw
                : this.userUploadedLogo[0]
            );
          }
          const saRole = this.allRoles.find((role) => role.title === "SA");
          if (!saRole) {
            this.loading = false;
            return this.$notify.error({
              title: "Error",
              message: "There should be super role to publish application",
            });
          }

          this.publishApp.menuManagementWithRoles.push(saRole._id);
          formData.append("path", this.publishApp.name);
          let filesData = await axios.post(
            `${app.API_URL}/multiFileUpload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (this.userUploadedLogo.length) {
            logoFormData.append("path", this.publishApp.name);
            let logoData = await axios.post(
              `${app.API_URL}/multiFileUpload`,
              logoFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            this.publishApp.logo = logoData.data.data;
          }

          this.publishApp.images = filesData.data.data;
          this.publishApp.type = "CUSTOM";
          this.publishApp.publish = "HOLD";
          // try {

          await this.$store.dispatch(
            "apps/createNewApplication",
            this.publishApp
          );
          if (this.getNewApplicationInfo.data != null) {
            this.$message.success(
              "Your application is added.We will verify and get back to you"
            );
            localStorage.removeItem("publishApp");
            this.$router.push({ path: "/apps/published" });
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getNewApplicationInfo.message,
            });
          }
          this.loading = false;
        } else {
          this.loading = false;
          this.$message("Please upload your application screens ");
        }
      } else {
        if (
          !this.publishApp.name ||
          !this.publishApp.description ||
          !this.publishApp.singleLineDescription
        ) {
          this.loading = false;
          return this.$message.warning("Please fill all required fields");
        } else if (
          this.publishApp.description &&
          this.publishApp.description.length < 100
        ) {
          this.loading = false;
          return this.$message.warning(
            "minimum characters in description is 100"
          );
        } else if (
          this.publishApp.industries.length == 0 ||
          this.publishApp.categories.length == 0
        ) {
          this.loading = false;
          return this.$message.warning(
            "please select industries and related categories"
          );
        } else if (
          !this.publishApp.icon &&
          !(this.userUploadedLogo.length == 1)
        ) {
          this.loading = false;
          return this.$message.warning("Please select App Icon");
        } else {
          this.loading = false;
          this.active = 1;
        }
      }
    },
    async previous() {
      this.loading = true;
      if (this.active == 2) {
        this.active = 1;
        this.fetchEntities();
        this.fetchRoles();
        this.loading = false;
      } else if (this.active == 1) {
        this.active = 0;
        this.loading = false;
      }

      if (this.active <= 0) this.active = 0;

      this.loading = false;
    },
    handleRemove() {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload() {},
    async fetchGroupsData() {
      if (this.publishApp.groups.length < 1) {
        return (this.publishApp.entities = []);
      }
      this.loading = true;
      let params = {
        page: 1,
        limit: 100,
        order_by: "name",
        order_type: "asc",
        selectedGroups: this.publishApp.groups,
      };
      await this.$store.dispatch("entityGroups/fetSelectedGroupsData", params);
      this.publishApp.entities = [];
      await this.getSelectedGroupsData.data.map(async (group) => {
        this.publishApp.entities = [
          ...this.publishApp.entities,
          ...group.entities,
        ];
        this.publishApp.entities = Array.from(
          new Set(this.publishApp.entities)
        );
      });
      this.loading = false;
    },
    async fetchAllCategories() {
      try {
        await this.$store.dispatch("apps/fetchAllCategories");
        if (this.getAllCategories) {
          this.allIndustries = [...this.getAllCategories.data];
        }
      } catch (err) {
        console.log("fetchAllCategories", err);
      }
    },
    async addCategory() {
      if (!this.industryId) {
        return this.$notify.error({
          title: "Error",
          message: "Please select industry type",
        });
      }
      let params = {
        _id: this.industryId,
        categoryName: this.newCategory,
      };
      try {
        await nodeAxios.patch("/create-new-category", params);
        this.categoryDialogVisible = false;
        this.fetchAllCategories();
        this.$notify.success({
          title: "Success",
          message: "category added",
        });
      } catch (err) {
        this.categoryDialogVisible = false;
        this.$notify.error({
          title: "Error",
          message: "error creating category",
        });
      }
    },



    async fetchAllApps() {
      this.loading = true;
      try {
        await this.$store.dispatch("apps/fetchAllApplications");
        this.loading = false;
      } catch (err) {
        console.log("fetchAllApps", err);
      }
    },
    beforeDestroy() {
      this.$store.commit(
        "navigationOpen/setCurrentMenu",
        {},
        {
          root: true,
        }
      );
    },
    createApp() {
      this.$router.push({ path: "/apps/created" });
    },

    async fetchCompanyGroups() {
      try {
        await this.$store.dispatch("entityGroups/fetEntityGroupsData", {
          get_all: true,
        });
      } catch (e) {
        console.log("fetchCompanyGroups", e);
      }
    },
    async fetchEntities() {
      try {
        let response = await nodeAxios.get("/entities/fetchAllEntities");
        if (response?.data?.data) {
          this.allEntities = [...response.data.data];
        }
      } catch (err) {
        console.log("fetchEntities", err);
      }
    },
    async fetchRoles() {
      let params = {
        page: 1,
        limit: 1000,
        order_by: "name",
        order_type: "asc",
        skip: 0,
      };
      await this.$store.dispatch("roles/fetchRoles", params);
      this.allRoles = this.getAllRoles ? this.getAllRoles.data : [];
      // this.publishApp.menuManagementWithRoles=[this.allRoles[0]._id];
      // this.allRoles = await this.allRoles.filter(role => role.title !== "SA");
    },
    // updateCategories() {
    //     // Clear the selected categories when the industries are changed
    //     this.selectedCategories = [];
    //     for (const industry of this.selectedIndustriesData) {
    //         const selectedCategoryObj = { [industry.name]: [] };
    //         for (const category of industry.categories) {
    //             if (this.selectedCategories.includes(category)) {
    //                 selectedCategoryObj[industry.name].push(category);
    //             }
    //         }
    //         this.selectedCategories.push(selectedCategoryObj);
    //     }
    // },
    handleIndustryChange() {
      if (!this.isFirstLoad) {
        // Filter out categories that are not associated with selected industries
        const selectedCategories = [];
        for (const industry of this.selectedIndustriesData) {
          selectedCategories.push(...industry.categories);
        }
        this.publishApp.categories = this.publishApp.categories.filter(
          (category) => selectedCategories.includes(category)
        );
      }
    },
    stepNavigation(step) {
      if (this.active > 0 && step < this.active) {
        this.active = step;
      } else if (step > this.active) {
        // this.active = step
        this.next();
      }
    },
    handleBack() {
      this.$router.push({ path: "/apps/published" });
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        return "menuApplicationUsers" + "White";
      } else {
        return selectedCategory + "-" + "White";
      }
    },
    setIconBgColor() {
      if (this.getCompanyDetails && this.getCompanyDetails?.button) {
        this.iconBgColor = this.getCompanyDetails?.button?.background;
      }
    },
  },
  beforeDestroy() {
    localStorage.removeItem("publishApp");
  },
  created() {
    const storedApp = localStorage.getItem("publishApp");
    if (storedApp) {
      this.publishApp = JSON.parse(storedApp);
      this.isFirstLoad = false;
    }
  },
  watch: {
    "$route.query.group"() {},
    "$route.query.key"() {},
    active: async function () {
      if (this.active == 1) {
        this.fetchCompanyGroups();
        this.fetchEntities();
        this.fetchRoles();
      }
    },
    publishApp: {
      handler(newVal) {
        localStorage.setItem("publishApp", JSON.stringify(newVal));
      },
      deep: true,
    },
    "publishApp.industries": {
      handler: "handleIndustryChange",
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.Category-in-MarketPlace {
  margin: 20px;
}
.el-form-item {
  margin-bottom: 10px;
}

.name-types {
  margin-top: 2%;

  .filename {
    height: 56px;
    margin: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;

    .el-upload-list__item-name {
      margin-top: 1em;

      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }

      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }

    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;

      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }

    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }

      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;

        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }

      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}

.float-right {
  float: right;
}

.imgSize {
  height: 4em;
  width: 4em;
}

.text-justify {
  text-align: justify;
  word-break: break-all;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-center {
  text-align: center !important;
}

.doc-title {
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #6c757d !important;
}

.activestatus {
  font-size: 14px;
  color: #6c757d !important;
}
.el-upload .upload-attchment-box.centered {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Adjust the height as needed */
}

.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #f562b3;
  }

  &.deleted::after {
    background: #8b1702;
  }

  &.archived::after {
    background: #404040;
  }

  &.disable::after {
    background: #c4c4c4;
  }

  &.viewed::after {
    background: #2f80ed;
  }
}

.cardsList {
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }

  .box-card {
    width: 100%;
  }
}
.circle-icon {
  width: 21px;
  height: 47px;
  border-radius: 50%;
  text-align: center;
  //line-height: 100px;
  //vertical-align: middle;
  margin-top: 10px;
}
.button-and-upload-container {
  display: flex;
  align-items: center;
}

.upload-container {
  margin-left: 20px; /* Adjust the spacing between the button and the div as needed */
}

.select-logo {
  padding-top: 50px;
}
.iconslistScrollable {
  margin: 20px;
  height: 67vh;
}
.icons-scroll {
  overflow-y: scroll;
  overflow-x: scroll;
  margin-right: 5px;
}
.icons-scroll::-webkit-scrollbar {
  width: 0.5em;
}
.icons-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.tabs-scroll {
  height: 40px;
  width: 100% !important;
}
</style>
<style lang="scss">
.top-buttons-wrapper {
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}

.new-entity-information {
  .el-dialog {
    width: 800px;
    padding-bottom: 15px;

    @media (max-width: 800px) {
      width: 700px;
      margin-top: 40px !important;
    }

    @media (max-width: 700px) {
      width: 600px;
      margin-top: 40px !important;
    }

    @media (max-width: 600px) {
      width: 500px;
      margin-top: 40px !important;
    }

    @media (max-width: 500px) {
      width: 400px;
      margin-top: 40px !important;
    }

    @media (max-width: 400px) {
      width: 300px;
      margin-top: 40px !important;
    }

    .entity-type-radio {
      @media (max-width: 400px) {
        & > label {
          & > span {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }
}
</style>